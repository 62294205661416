<template>
  <div class="container-fluid p-5">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5>Input Nilai Sikap</h5>
              <p>Nama Pengajar : {{ guru.fullname }}</p>
            </div>
          </div>
          <div class="card-body">
            <div class="">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleFormControlInput1"
                    >Pilih kelas yang diajar:<span class="text-danger"
                      >*</span
                    ></label
                  >
                  <select
                    class="form-control"
                    v-model="data.kelasid"
                    @change="changeSiswa"
                  >
                    <option value="">-- select kelas --</option>
                    <option
                      v-for="k in kelas"
                      :key="k.id"
                      :value="k.id"
                      :id-ta="k.id_ta"
                       :id-mapel="k.idmapel"
                    >
                      {{ k.classname }} - {{ k.nama_mapel }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- {{ data.kelasid }} -->

            <div class="mt-5" v-if="data.kelasid > 0">
                <div v-if="isLoading">
                    <ContentLoader />
                  </div>
              <div class="table-responsive table-container" v-else>
                <table
                  id="datatable-search"
                  class="table table-bordered"
                 
                >
                  <thead class="thead-light">
                    <tr>
                        <th
                            style="
                              font-size: 10pt;
                              color: gray;
                              font-weight: bold;
                              position: sticky;
                              left: 0;
                              top: 0;
                              background-color: #f2f2f2;
                              z-index: 2;
                            "
                          >
                        Nama Siswa
                      </th>
                      <th
                        v-for="(ranah, index) in kategori"
                        :key="ranah.id"
                        style="
                                font-size: 10pt;
                                color: gray;
                                font-weight: bold;
                              "
                              align="center"
                        :colspan="`${ranah.generate}`"
                      >
                        {{ index + 1 }}
                      </th>
                    </tr>
                    <!-- <tr style="border: 2px solid black;" >
                                            <th
                                                style="text-align: center; vertical-align: middle; position: sticky; left: 0; top:0; background-color:#fff; z-index: 2; border: 2px solid black;">
                                                Nama Siswa</th>
                                            <template v-for="ranah in kategori">
                                                <th v-for="n in ranah.generate" :key="n"
                                                    style="text-align: center; vertical-align: middle; border: 2px solid black;"
                                                    align="center">{{ n }}</th>
                                            </template>
</tr> -->
                  </thead>
                  <tbody>
                    <tr
                      v-for="m in siswa"
                      :key="m.id"
                      
                    >
                    <td
                            style="
                              font-size: 10pt;
                              color: gray;
                              position: sticky;
                              left: 0;
                              background-color: #fff;
                              z-index: 3;
                            "
                          >
                        {{ m.nama }}
                      </td>
                      <template v-for="ranah in kategori" :key="ranah.id">
                        <td
                            style="
                              font-size: 10pt;
                              color: gray;
                              position: sticky;
                              left: 0;
                              background-color: #fff;
                              z-index: 3;
                            "
                          >
                          <select
                            class="form-control"
                            style="text-align: center; text-align-last: center;"
                            @change="
                              handleSelectChange(
                                m.id_siswa,
                                ranah.id,
                                ranah.id_sub,
                                n,
                                $event
                              )
                            "
                          >
                          
                            <option
                            style=" text-align: center;"
                              :value="
                                getNilai(m.id_siswa, ranah.id, ranah.id_sub, n)
                              "
                            >
                              {{
                                getNilai(m.id_siswa, ranah.id, ranah.id_sub, n)
                              }}
                            </option>
                            <option
                              v-for="k in filteredKetNilai(ranah)"
                              :value="k.kode"
                              :key="k.id"
                            >
                              {{ k.kode }}
                            </option>
                          </select>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>

              <ol>
                <li v-for="ranah in kategori" :key="ranah.id">
                  {{ ranah.nama_kategori }}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { computed, ref, reactive, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";
import { ContentLoader } from "vue-content-loader";

// const body = document.getElementsByTagName("body")[0];
export default {
  name: "inputnilai",
  components: {
    ContentLoader,
  },
  // created() {
  //     this.$store.state.hideConfigButton = true;
  //     this.toggleDefaultLayout();
  //     body.classList.remove("bg-gray-100");
  // },
  // beforeUnmount() {
  //     this.$store.state.hideConfigButton = false;
  //     this.toggleDefaultLayout();
  //     body.classList.add("bg-gray-100");
  // },

  setup() {
    const inputRefs = ref({});
    const isLoading = ref(false);

    const data = reactive({
      kelasid: "",
      idmapel: "",
    });

    const guru = reactive({
      fullname: "",
      nickname: "",
      gender: "",
      birthplace: "",
      birthdate: "",
      marital_status: "",
      wni: "",
      religion: "",
      edulevel: "",
      address: "",
      id_province: "",
      id_city: "",
      id_district: "",
      id_subdistrict: "",
      cellphone: "",
      email: "",
      lang: "",
      nuptk: "",
    });

    const validation = ref([]);
    const store = useStore();
    const route = useRoute();

    onMounted(async () => {
      isLoading.value = true;
      try {
        await store.dispatch("guru/getDetail", route.params.id);
        await store.dispatch("bebanmengajar/getKelasGuruData", route.params.id);
        // await store.dispatch("bebanmengajar/getSiswaSikap")
        await store.dispatch("keterangannilai/getAll");
        await store.dispatch("nilaisikap/getDataMapel", data.idmapel);
        await store.dispatch("kategorisikap/getKategoriMapel");
        const inputs = document.querySelectorAll(".form-nilai");
        inputs.forEach((input) => {
          input.addEventListener("keydown", focusNextInput());
        });
      } catch (error) {
        // console.error("Failed to fetch kelas data:", error);
      }

      // Set a timeout to simulate loading delay (e.g., 2 seconds)
      setTimeout(() => {
        isLoading.value = false;
      }, 30000);
    });

    onUnmounted(() => {
      const inputs = document.querySelectorAll(".form-nilai");
      inputs.forEach((input) => {
        input.removeEventListener("keydown", focusNextInput());
      });
    });

    const kelas = computed(() => {
      return store.getters["bebanmengajar/getKelasGuruData"];
    });

    const siswa = computed(() => {
      return store.getters["bebanmengajar/getSiswaSikap"];
    });

    const kategori = computed(() => {
      return store.getters["kategorisikap/getKategoriMapel"];
    });

    const changeSiswa = async () => {
      isLoading.value = true;
      try {
        data.idmapel =
          event.target.options[event.target.selectedIndex].getAttribute(
            "id-mapel"
          );
        await store.dispatch("nilaisikap/getDataMapel", data.idmapel);
        await store.dispatch("bebanmengajar/getSiswaSikap", data.kelasid);
        await store.dispatch("kategorisikap/getKategoriMapel", data.kelasid);
      } catch (error) {
        console.error("Failed to fetch siswa data:", error);
      }
      // Set a timeout to simulate loading delay (e.g., 2 seconds)
      setTimeout(() => {
        isLoading.value = false;
      }, 5000);
    };

    const ketnilai = computed(() => {
      return store.getters["keterangannilai/getAll"] || [];
    });

    const filteredKetNilai = (ranah) => {
      const keterangannilai_id = ranah.ketnilai || [];
      return ketnilai.value.filter((k) => keterangannilai_id.includes(k.id));
    };

    const nilaisikap = computed(() => {
      return store.getters["nilaisikap/getDataMapel"];
    });

    const focusNextInput = (e) => {
      var total = 0;

      // Loop melalui setiap objek dalam kategori.value dan tambahkan jumlahnya ke total
      for (var i = 0; i < kategori.value.length; i++) {
        total += kategori.value[i].jumlah_generate;
      }

      // Hasilnya akan disimpan dalam variabel 'total'
      // console.log("Total jumlah generate:", total);

      // const posisi = index;

      if (e.key === "Enter" || e.key === "Tab") {
        e.preventDefault();
        const targetInput = e.target;
        const inputs = document.querySelectorAll(".form-nilai");
        const currentIndex = Array.from(inputs).indexOf(targetInput);

        const nextIndex = (currentIndex + total) % inputs.length;
        inputs[nextIndex].focus();
      }
    };

    const getNilai = (idSiswa, idKategori) => {
      const nilai = nilaisikap.value.find((item) => {
        return item.id_siswa === idSiswa && item.id_kategori === idKategori;
      });

      return nilai ? nilai.nilai : "";
    };

    async function handleSelectChange(
      idsiswa,
      kategoriItemId,
      subkategoriItemId,
      order,
      event
    ) {
      try {
        // Aksi yang ingin Anda lakukan ketika nilai elemen select berubah
        const selectedValue = event.target.value;

        // console.log("Selected value changed to:", selectedValue);

        let formData = new FormData();
        formData.append("nilai", selectedValue);
        formData.append("id_siswa", idsiswa);
        formData.append("id_subkategori", subkategoriItemId);
        formData.append("id_kategori", kategoriItemId);
        formData.append("kelas_id", data.kelasid);
        formData.append("id_mapel", data.idmapel);
        formData.append("order", order);

        console.log("ini form", formData);

        await store.dispatch("nilaisikap/store", formData);

        // Tambahan logika atau pemanggilan fungsi sesuai kebutuhan
      } catch (error) {
        validation.value = error;

        Swal.fire({
          title: "GAGAL!",
          text: validation.value.error,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });

        console.error("An error occurred:", error);
      }
    }

    watch(
      () => store.state.guru.details,
      (newguru) => {
        //console.log('newguru',newguru);
        if (newguru) {
          const {
            fullname,
            nickname,
            gender,
            birthplace,
            birthdate,
            marital_status,
            wni,
            religion,
            edulevel,
            address,
            id_province,
            id_city,
            id_district,
            id_subdistrict,
            cellphone,
            email,
            lang,
            nuptk,
          } = newguru;
          guru.fullname = fullname;
          guru.nickname = nickname;
          guru.gender = gender;
          guru.birthplace = birthplace;
          guru.birthdate = birthdate;
          guru.marital_status = marital_status;
          guru.wni = wni;
          guru.religion = religion;
          guru.edulevel = edulevel;
          guru.address = address;
          guru.id_province = id_province;
          guru.id_city = id_city;
          guru.id_district = id_district;
          guru.id_subdistrict = id_subdistrict;
          guru.cellphone = cellphone;
          guru.email = email;
          guru.lang = lang;
          guru.nuptk = nuptk;
        }
      }
    );

    return {
      validation,
      guru,
      kelas,
      data,
      changeSiswa,
      siswa,
      kategori,
      nilaisikap,
      inputRefs,
      focusNextInput,
      ketnilai,
      filteredKetNilai,
      handleSelectChange,
      getNilai,
      isLoading,
    };
  },

  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
  },
};
</script>

<style>
.table-container {
  overflow: auto;
  max-height: 400px;
}

table.table-bordered th {
  position: sticky;
  top: 0;
  background-color: white;
}

.form-nilai {
  max-width: 100px;
  border: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

td {
  color: black;
}
</style>