<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h5 class="mb-0">Rekap Nilai</h5>
                <p>Kelas: {{ kelas.classname }}</p>
                <p>Tahun ajaran: {{ tasaktif.desc }}</p>
              </div>
              <div class="flex">
                <router-link
                  :to="{ name: 'admin-perwalian' }"
                  class="btn btn-primary btn-sm"
                  >Kembali</router-link
                >
                <a
                  :href="`${this.$baseURL}/export-rekap-sd-mid?kelasid=${$route.params.id}`"
                  target="_blank"
                  class="btn btn-sm btn-success"
                >
                  Export
                </a>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div v-if="isLoading">
              <ContentLoader />
            </div>

            <div class="table-responsive table-container" v-else>
              <table id="datatable-search" class="table table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th
                      rowspan="2"
                      style="
                         font-size: 10pt;
                        color: gray;
                        font-weight: bold;
                        position: sticky;
                        left: 0;
                        top: 0;
                        text-align: center;
                        vertical-align: middle; 
                        background-color: #f2f2f2;
                        z-index: 30;
                        height: 100%;
                        display: table-cell; 
                      "
                    >
                      Nama
                    </th>
                    <template
                      v-for="(items, kelompok) in nilairekap.groupmapel"
                      :key="kelompok"
                    >
                      <template v-for="p in items" :key="p.id">
                      <th
                        rowspan="2"
                        style="
                           font-size: 10pt;
                        color: gray;
                        font-weight: bold;
                        position: sticky;
                        left: 0;
                        top: 0;
                        text-align: center;
                        vertical-align: middle; 
                        background-color: #f2f2f2;
                        z-index: 20;
                        height: 100%;
                        display: table-cell; 
                        "
                      >
                        {{ p.nama_mapel }}
                      </th>
                    </template>
                    </template>
                    <th
                      rowspan="2"
                      style="
                        font-size: 10pt;
                        color: gray;
                        font-weight: bold;
                        position: sticky;
                        left: 0;
                        top: 0;
                        text-align: center;
                        vertical-align: middle; 
                        background-color: #f2f2f2;
                        z-index: 20;
                        height: 100%;
                        display: table-cell; 
                      "
                    >
                      Nilai akhir total
                    </th>

                    <th
                      colspan="3"
                      style="
                        font-size: 10pt;
                        color: gray;
                        font-weight: bold;
                        position: sticky;
                        top: 0;
                        background-color: #f2f2f2;
                        z-index: 20;
                      "
                    >
                      Kehadiran
                    </th>
                    <th
                      rowspan="2"
                      style="
                        font-size: 10pt;
                        color: gray;
                        font-weight: bold;
                        position: sticky;
                        left: 0;
                        top: 0;
                        text-align: center;
                        vertical-align: middle; 
                        background-color: #f2f2f2;
                        z-index: 30;
                        height: 100%;
                        display: table-cell; 
                      "
                    >
                      Peringkat
                    </th>
                  </tr>
                  <tr>
                   
                    <template v-for="type in ['S', 'I', 'A']" :key="type">
                      <th
                        style="
                          font-size: 10pt;
                          color: gray;
                          font-weight: bold;
                          position: sticky;
                          top: 40px; /* Sesuaikan dengan tinggi header sebelumnya */
                          background-color: #f2f2f2;
                          z-index: 10;
                        "
                      >
                        {{ type }}
                      </th>
                    </template>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="m in nilairekap.getSiswa" :key="m.id">
                    <td
                      style="
                        font-size: 10pt;
                        color: gray;
                        position: sticky;
                        left: 0;
                        background-color: #fff;
                        z-index: 3;
                      "
                    >
                      {{ m.fullname }}
                    </td>
                    <template
                      v-for="(items, kelompok) in nilairekap.groupmapel"
                      :key="kelompok"
                    >
                      <template v-for="p in items" :key="p.id">
                   
                      <td
                        style="
                          font-size: 10pt;
                          color: gray;

                          left: 0;
                          background-color: #fff;

                          text-align: center;
                        "
                      >
                        {{
                          (() => {
                            const nilai = getNilaiNA(
                              m.id_student,
                              p.mapel_id
                            );
                            return nilai == null || nilai === ""
                              ? "-"
                              : Math.round(nilai);
                          })()
                        }}
                      </td>
                    </template>
                    </template>
                    <td
                      style="
                        font-size: 10pt;
                        color: gray;

                        left: 0;
                        background-color: #fff;

                        text-align: center;
                      "
                    >
                      {{
                        Math.round(
                          calculateTotalNilai(m.id_student) == null
                            ? "-"
                            : calculateTotalNilai(m.id_student)
                        )
                      }}
                    </td>
                    <td
                      style="
                        text-align: center;
                        font-size: 10pt;
                        color: gray;

                        left: 0;
                        background-color: #fff;
                      "
                    >
                      {{ getKehadiran(m.id_student, "S") }}
                    </td>
                    <td
                      style="
                        font-size: 10pt;
                        color: gray;

                        left: 0;
                        background-color: #fff;

                        text-align: center;
                      "
                    >
                      {{ getKehadiran(m.id_student, "I") }}
                    </td>
                    <td
                      style="
                        font-size: 10pt;
                        color: gray;

                        left: 0;
                        background-color: #fff;

                        text-align: center;
                      "
                    >
                      {{ getKehadiran(m.id_student, "A") }}
                    </td>
                    <td
                      style="
                        font-size: 10pt;
                        color: gray;

                        left: 0;
                        background-color: #fff;

                        text-align: center;
                      "
                    >
                      {{ getPeringkat(m.id_student) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card-footer d-flex justify-content-between"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { ContentLoader } from "vue-content-loader";
// import Api from '../../../api/Api'

export default {
  name: "kelasIndex",
  components: {
    ContentLoader,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const isLoading = ref(false);
    const kelas = reactive({
      classname: "",
      id_ta: "",
      wali_kelas: "",
      guru_pendamping: "",
      id_unitlevel: "",
    });
    const data = reactive({
      // currentPage: 1,
      perPage: 100,
      search: "",
      raport: {
        file: "",
        id_studentactive: "",
      },
      pages: "perwalian-view",
    });

    const isLoggedIn = computed(() => {
      //get getter "isLoggedIn" dari module "auth"
      return store.getters["auth/isLoggedIn"];
    });

    const token = isLoggedIn.value;
    watch(
      () => store.state.kelas.details,
      (newkelas) => {
        if (newkelas) {
          const { master_kelas, id_ta, id_instructor_1, id_instructor_2 } =
            newkelas;
          kelas.classname = master_kelas.classname;
          kelas.id_ta = id_ta;
          kelas.wali_kelas = id_instructor_1;
          kelas.guru_pendamping = id_instructor_2;
          kelas.id_unitlevel = master_kelas.id_unitlevel;
        }
      }
    );

    const router = useRouter();
    onMounted(async () => {
      isLoading.value = true;
      await store.dispatch("siswa/getSiswaKelas", {
        page: 1,
        perPage: data.perPage,
        search: data.search,
        kelasId: route.params.id,
      });

      await store.dispatch("kelas/getDetail", route.params.id);
      await store.dispatch("nilai/getNilaiRekapSd", route.params.id);

      await store.dispatch("tahunajaran/getActive");

      const permissions = localStorage.getItem("permission");

      if (!permissions.includes(data.pages)) {
        router.push("/error"); // Ganti '/error-page' dengan rute halaman error Anda
      }
      setTimeout(() => {
        isLoading.value = false;
      }, 30000);
    });

    const nilairekap = computed(() => {
      return store.getters["nilai/getNilaiRekapSd"];
    });

    const siswa = computed(() => nilairekap.value.getSiswa);
    const nilaiP = computed(() => nilairekap.value.nilaip);
    const nilaiK = computed(() => nilairekap.value.nilaik);
    const nilaiNA = computed(() => nilairekap.value.nilaiNA);
    const nilaiAbsen = computed(() => nilairekap.value.absen);

    const getNilaiP = (idSiswa, idMapel) => {
      const nilai = nilaiP.value.find((item) => {
        return item.siswa_id === idSiswa && item.id_mapel === idMapel;
      });

      return nilai ? nilai.nilai : "";
    };

    const getNilaiK = (idSiswa, idMapel) => {
      const nilai = nilaiK.value.find((item) => {
        return item.siswa_id === idSiswa && item.id_mapel === idMapel;
      });

      return nilai ? nilai.nilai : "";
    };

    const getNilaiNA = (idSiswa, idMapel) => {
      const nilai = nilaiNA.value.find((item) => {
        return item.siswa_id === idSiswa && item.id_mapel === idMapel;
      });

      return nilai ? nilai.nilai : "";
    };

    const calculateTotalNilai = (idSiswa) => {
      let totalNilai = 0;

      // Menjumlahkan semua nilai P untuk siswa tersebut
      // totalNilai += nilaiP.value
      //   .filter((item) => item.siswa_id === idSiswa)
      //   .reduce((sum, item) => sum + item.nilai, 0);

      // // Menjumlahkan semua nilai K untuk siswa tersebut
      // totalNilai += nilaiK.value
      //   .filter((item) => item.siswa_id === idSiswa)
      //   .reduce((sum, item) => sum + item.nilai, 0);

      // Menjumlahkan semua nilai NA untuk siswa tersebut
      totalNilai += nilaiNA.value
        .filter((item) => item.siswa_id === idSiswa)
        .reduce((sum, item) => sum + item.nilai, 0);

      return totalNilai;
    };

    const getPeringkat = (idSiswa) => {
      const siswaDenganTotalNilai = siswa.value.map((s) => ({
        ...s,
        totalNilai: Math.round(calculateTotalNilai(s.id_student)),
      }));

      // Mengurutkan data berdasarkan total nilai (descending)
      const sortedSiswa = [...siswaDenganTotalNilai].sort((a, b) => b.totalNilai - a.totalNilai);

      // Menentukan peringkat berdasarkan total nilai (descending)
      let previousTotalNilai = null;
      let currentRank = 0;
      let rankCounter = 0;
      const siswaPeringkat = sortedSiswa.map((s) => {
        rankCounter++;
        if (s.totalNilai !== previousTotalNilai) {
          currentRank = rankCounter;
        }
        previousTotalNilai = s.totalNilai;
        return {
          ...s,
          peringkat: currentRank,
        };
      });

      const siswaIni = siswaPeringkat.find((s) => s.id_student === idSiswa);
      return siswaIni ? siswaIni.peringkat : "-";
    };

    // Fungsi untuk membuat daftar siswa dengan total nilai dan peringkat

    const getKehadiran = (siswaId, status) => {
      const kehadiran = nilaiAbsen.value.filter(
        (k) => k.student_id === siswaId && k.status === status
      );

      // Mengembalikan jumlah entri dengan status tertentu
      return kehadiran.length > 0 ? kehadiran.length : "-";
    };

    const tasaktif = computed(() => {
      return store.getters["tahunajaran/getActive"];
    });

    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      getPeringkat,
      calculateTotalNilai,
      tasaktif,
      getKehadiran,
      getNilaiP,
      getNilaiK,
      getNilaiNA,
      data,
      isLoggedIn,
      token,
      kelas,
      isLoading,
      nilairekap,
    };
  },
};
</script>