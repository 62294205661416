import Api from '../../api/Api'
const bebanmengajar = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        datas: [],
        details: {},
        listsiswamapelaktif: [],
        listsiswamapelnonaktif: [],
        getkelasbyguru: [],
        listsiswa: [],
        listsiswasikap: [],
        listsiswasikapwalas: [],
        listpersiswa: [],
        listdata: [],
        listdatagroup: [],
        getkelasbywalas: [],
        getkelasbyguruKB: [],
    },

    //mutations
    mutations: {

        SET_DATA(state, datas) {
            state.datas = datas
        },

        SET_LISTDATA(state, listdata) {
            state.listdata = listdata
        },

        SET_LISTDATAGROUP(state, listdatagroup) {
            state.listdatagroup = listdatagroup
        },

        GET_DETAIL(state, details) {
            state.details = details
        },

        SET_DATA_SISWA_MAPEL_AKTIF(state, listsiswamapelaktif) {
            state.listsiswamapelaktif = listsiswamapelaktif
        },

        SET_DATA_SISWA_MAPEL_NONAKTIF(state, listsiswamapelnonaktif) {
            state.listsiswamapelnonaktif = listsiswamapelnonaktif
        },

        SET_LISTSISWA_DATA(state, listsiswa) {
            state.listsiswa = listsiswa
        },

        SET_LISTSISWASIKAP_DATA(state, listsiswasikap) {
            state.listsiswasikap = listsiswasikap
        },

        SET_LISTSISWASIKAPWALAS_DATA(state, listsiswasikapwalas) {
            state.listsiswasikapwalas = listsiswasikapwalas
        },

        SET_LISTPERSISWA_DATA(state, listpersiswa) {
            state.listpersiswa = listpersiswa
        },


        SET_KELASGURU_DATA(state, getkelasbyguru) {
            state.getkelasbyguru = getkelasbyguru
        },

        SET_KELASWALAS_DATA(state, getkelasbywalas) {
            state.getkelasbywalas = getkelasbywalas
        },

        SET_KELASGURU_DATAKB(state, getkelasbyguruKB) {
            state.getkelasbyguruKB = getkelasbyguruKB
        },
    },

    _actions: {

        getDetail({ commit }, payload) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/bebanmengajar/edit/${payload}`)
                .then(response => {
                    // console.log('ini detail', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_DETAIL', response.data.data)

                })
        },

        refreshsiswa({ commit }, payload) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/bebanmengajar/refreshsiswa/${payload}`)
                .then(response => {
                    // console.log('ini detail', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_DETAIL', response.data.data)

                })
        },

        getData({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let taId = payload ? payload.tasId : '';
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/bebanmengajar?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&tahunajaran=${taId}`)
                .then(response => {
                    //    console.log('ini parent',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        getKelasGuruData({ commit }, payload) {
            // //console.log('ini parsing',payload);

            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/bebanmengajar/getkelasbyguru/${payload}`)
                .then(response => {
                    // console.log('ini parent mapel',response.data.data)
                    commit('SET_KELASGURU_DATA', response.data.data)
                })
        },

        getKelasGuruDataKB({ commit }, payload) {
            // //console.log('ini parsing',payload);

            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/bebanmengajar/getkelasbygurukb/${payload}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_KELASGURU_DATAKB', response.data.data)
                })
        },

        getKelasWalasData({ commit }, payload) {
            // //console.log('ini parsing',payload);

            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/bebanmengajar/getkelasbywalas/${payload}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_KELASWALAS_DATA', response.data.data)
                })
        },



        getSiswa({ commit }, payload) {
            let ids = payload ? payload.id : ''
            let idmapel = payload ? payload.idmapel : ''
            let idguru = payload ? payload.guruid : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`bebanmengajar/listsiswabebanmengajar-old?id=${ids}&idmapel=${idmapel}&guruid=${idguru}`)
                .then(response => {
                    // console.log('get siswa bebanmengajar', response.data.data)
                    commit('SET_LISTSISWA_DATA', response.data.data)
                })
        },

        getSiswaSikap({ commit }, payload) {

            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`bebanmengajar/listsiswabebanmengajarsikap?id=${payload}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_LISTSISWASIKAP_DATA', response.data.data)
                })
        },

        // function baru get siswa nilai sikap
        getSiswaSikapwalas({ commit }, payload) {

            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`bebanmengajar/listsiswabebanmengajarsikapwalas?id=${payload}`)
                .then(response => {
                    console.log('ini parent', response.data.data)
                    commit('SET_LISTSISWASIKAPWALAS_DATA', response.data.data)
                })
        },

        getPerSiswa({ commit }, payload) {

            let page = payload ? payload.pages : ''
            let params = payload ? payload.param : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`bebanmengajar/listpersiswabebanmengajar?id=${params}&page=${page}`)
                .then(response => {
                    // console.log('ini parent',response.data.data)
                    commit('SET_LISTPERSISWA_DATA', response.data.data)
                })
        },

        getDataSiswaAktifMapel({ commit }, payload) {
            // //console.log('ini parsing',payload);

            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/bebanmengajar/listsiswaaktif/${payload}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_DATA_SISWA_MAPEL_AKTIF', response.data.data)
                })
        },

        getListData({ commit }, payload) {
            console.log('ini parsing', payload);

            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/bebanmengajar/list/${payload}`)
                .then(response => {

                    commit('SET_LISTDATA', response.data.data)
                })
        },
        
        getListDataGroup({ commit }, payload) {
            console.log('ini parsing', payload);
            let idkelas = payload ? payload.idkelas : ''
            let id = payload ? payload.id : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/bebanmengajar/list/${idkelas}?&monitoring=1&id=${id}`)
                .then(response => {

                    commit('SET_LISTDATAGROUP', response.data.data)
                })
        },


        getDataSiswaNonAktifMapel({ commit }, payload) {
            // //console.log('ini parsing',payload);

            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/bebanmengajar/listsiswanonaktif/${payload}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_DATA_SISWA_MAPEL_NONAKTIF', response.data.data)
                })
        },



        destroy({ commit }, payload) {
            // Define variable token
            const token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                Api.defaults.headers.common['Authorization'] = "Bearer " + token
                Api.get(`/bebanmengajar/delete/${payload}`)
                    .then(response => {
                        // Commit ke mutation SET_user
                        commit('SET_DATA', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error.response.data)
                        reject(error.response.data)
                    })
            })
        },

        updateStatus({ commit }, payload) {


            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post(`/bebanmengajar/updatestatus/${payload}`)

                    .then(response => {

                        // //console.log(response.data.data)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },


        store({ commit }, formData) {
            console.log('ini user', formData);

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/bebanmengajar', formData)

                    .then(response => {

                        //console.log(response.data.data)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },




    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {

        getData(state) {
            return state.datas
        },

        getListData(state) {
            return state.listdata
        },

        getListDataGroup(state) {
            return state.listdatagroup
        },

        getDetai(state) {
            return state.details
        },

        getKelasGuruData(state) {
            return state.getkelasbyguru
        },

        getKelasGuruDataKB(state) {
            return state.getkelasbyguruKB
        },

        getKelasWalasData(state) {
            return state.getkelasbywalas
        },

        getSiswa(state) {
            return state.listsiswa
        },

        getSiswaSikap(state) {
            return state.listsiswasikap
        },

        getSiswaSikapwalas(state) {
            return state.listsiswasikapwalas
        },

        getPerSiswa(state) {
            return state.listpersiswa
        },

        getDataSiswaAktifMapel(state) {
            return state.listsiswamapelaktif
        },

        getDataSiswaNonAktifMapel(state) {
            return state.listsiswamapelnonaktif
        }







    }

}

export default bebanmengajar