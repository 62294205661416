<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Data Siswa Aktif</h5>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-between">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show: </span>
                  <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                    <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData" placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2" />
                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col ms-auto my-auto mr-5">
                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.unit" @change="changeUnit">
                    <option value="">-- select Unit --</option>
                    <option v-for="unit in units" :key="unit.id" :value="unit.id">
                      {{ unit.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col ms-auto my-auto mr-5">
                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.level" @change="changeUnitlevel">
                    <option value="">-- select Unitlevel --</option>
                    <option v-for="unitlevels in unitlevel" :key="unitlevels.id" :value="unitlevels.id">{{ unitlevels.code }} - {{ unitlevels.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col ms-auto my-auto mr-5">
                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.kelas" @change="changeKelas">
                    <option value="">-- select Kelas --</option>
                    <option v-for="kelas in kelasmaster" :key="kelas.id" :value="kelas.id">
                      {{ kelas.classname }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- <div class="col ms-auto my-auto mr-5">
                <a :href="`https://api-v2.annisaa-izada.sch.id/exportsiswaaktif?unit=${data.unit}&unitlevel=${data.level}&kelas=${data.kelas}`" class="btn btn-primary btn-md">
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i> export
                </a>
              </div> -->

               <div class="col ms-auto my-auto mr-5">
                <a :href="`${this.$baseURL}/exportsiswaaktif?unit=${data.unit}&unitlevel=${data.level}&kelas=${data.kelas}`" class="btn btn-primary btn-sm">
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i> export
                </a>
                <!-- <a :href="`${this.$baseURL}/exportsiswaaktifsibling?unit=${data.unit}&unitlevel=${data.level}&kelas=${data.kelas}`" class="btn btn-success btn-sm">
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i> export sibling
                </a> -->
              </div> 

            </div>
            <div class="table-responsive">
              <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                <thead class="thead-light">
                  <tr style="max-height: 5px">
                    <th class="text-center">Nama Siswa</th>
                    <!-- <th class="text-center">Unitlevel</th> -->
                    <th class="text-center">Unit</th>
                    <th class="text-center">Kelas</th>
                    <th class="text-center">NISN</th>
                    <th class="text-center">#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="m in siswas.data" :key="m.id" style="height: 1px">
                    <td>{{ m.fullname }}</td>
                    <!-- <td align="center">{{ m.codeunit }}</td> -->
                    <td align="center">{{ m.unitname }}</td>
                    <td class="text-center">{{ m.classname }}</td>
                    <td><input  v-if="datapermissions.includes('siswaaktif-edit')" class="form-control form-control-sm"  :id_reg="m.id_reg"
                          @input="updateNisn"
                          :value="m.nisn" size="10" maxlength="10" />
                          <input  v-else class="form-contro form-control-sml"  :id_reg="m.id_reg"
                          @input="updateNisn" disabled
                          :value="m.nisn" size="10" /></td>
                    <td class="text-center">
                      <router-link v-if="datapermissions.includes('siswaaktif-edit')" :to="{ name: 'admin-siswaaktif-show', params: { id: m.id } }" class="btn btn-success btn-xs m-0 me-2">
                        <i class="fas fa-eye"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-between">
            <span>
              <p>Menampilkan {{ siswas.to }} dari {{ siswas.total }}</p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li class="page-item" :class="{ disabled: siswas.current_page === 1 }">
                  <a class="page-link" @click="changePage(siswas.current_page - 1)" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li v-for="page in siswas.links" :key="page" :class="{ 'page-item': true, active: page.active === true}">
                  <a class="page-link" @click="changePage(page.label)" href="#"
                    v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">
                    {{ page.label }}
                  </a>
                </li>
                <li class="page-item" :class="{ disabled: siswas.current_page === siswas.last_page }">
                  <a class="page-link" @click="changePage(siswas.current_page + 1)" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";

export default {
  name: "siswaIndex",
  setup() {
    const store = useStore();
    const router = useRouter();
    const validation = ref([]);
    const data = reactive({
      perPage: 10,
      search: "",
      kelas: "",
      unitlevel: "",
      level: "",
      unit: "",
      pages: "siswaaktif-view",
    });

    onMounted(async () => {
      await store.dispatch("siswa/getSiswaAktif");
      await store.dispatch("units/getAll");
      await store.dispatch("unitlevel/getAll");
      await store.dispatch("masterkelas/getAll");
      await store.dispatch("user/getDataPermission");
      const permissions = localStorage.getItem("permission");

      if (!permissions.includes(data.pages)) {
        router.push("/error"); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const isNumeric = (value) => {
      return !isNaN(parseFloat(value)) && isFinite(value);
    }

    const datapermissions = computed(() => store.getters["user/getDataPermission"]);
    const siswas = computed(() => store.getters["siswa/getSiswaAktif"]);
    const units = computed(() => store.getters["units/getAll"]);
    const unitlevel = computed(() => store.getters["unitlevel/getAll"]);
    const kelasmaster = computed(() => store.getters["masterkelas/getAll"]);
    const perPageOptions = [5, 10, 15, 25, 50, 100];

    const changePage = async (page) => {
      await store.dispatch("siswa/getSiswaAktif", {
        page: page,
        perPage: data.perPage,
        search: data.search,
        kelas: data.kelas,
        unit: data.unit,
        unitlevel: data.level,
      });
    };


    // const focusNextInput = (e, m, index) => {
    //   if (e.key === "Enter" || e.key === "Tab") {
    //     e.preventDefault();
    //     const targetInput = e.target;
    //     const inputs = document.querySelectorAll(".form-control");
    //     const currentIndex = Array.from(inputs).indexOf(targetInput);
    //     const columns = 3; // Number of columns in your table

    //     // Calculate the next index based on the current column
    //     const nextIndex = currentIndex + 1 < inputs.length ? currentIndex + 1 : (index + 1) * columns;

    //     inputs[nextIndex].focus();
    //   }
    // };


    const changePerPage = async () => {
      await store.dispatch("siswa/getSiswaAktif", {
        page: 1,
        perPage: data.perPage,
        search: data.search,
        kelas: data.kelas,
        unit: data.unit,
        unitlevel: data.level,
      });
    };

    const changeUnit = async () => {
      await store.dispatch("siswa/getSiswaAktif", {
        page: 1,
        perPage: data.perPage,
        search: data.search,
        kelas: data.kelas,
        unit: data.unit,
        unitlevel: data.level,
      });
      await store.dispatch("unitlevel/getAll", data.unit);
    };

    const changeUnitlevel = async () => {
      await store.dispatch("siswa/getSiswaAktif", {
        page: 1,
        perPage: data.perPage,
        search: data.search,
        kelas: data.kelas,
        unit: data.unit,
        unitlevel: data.level,
      });
      await store.dispatch("masterkelas/getAll", data.level);
    };

    const changeKelas = async () => {
      await store.dispatch("siswa/getSiswaAktif", {
        page: 1,
        perPage: data.perPage,
        search: data.search,
        kelas: data.kelas,
        unit: data.unit,
        unitlevel: data.level,
      });
    };

    const searchData = async () => {
      await store.dispatch("siswa/getSiswaAktif", {
        page: 1,
        perPage: data.perPage,
        search: data.search,
        kelas: data.kelas,
        unit: data.unit,
        unitlevel: data.level,
      });
    };

    async function updateNisn(e) {
      const inputValue = e.target.value;
      const id_reg = e.target.getAttribute('id_reg');
      let formData = new FormData();
      formData.append("nisn", inputValue);
      formData.append("id_reg", id_reg);
      formData.append("_method", "POST");

      await store
        .dispatch("siswa/updateNisn",formData)
        .then(() => {
          //redirect ke dashboard
          // store.dispatch("medicalinfo/getData");
        })
        .catch((error) => {
          validation.value = error;
        });
    }

    const confirmDelete = (id) => {
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin menghapus data?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch("siswa/destroy", id).then(() => {
            store.dispatch("siswa/getSiswaAktif");
            router.push({ name: "admin-siswa" });
            Swal.fire({
              title: "BERHASIL!",
              text: "Data Berhasil Dihapus!",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
          });
        }
      });
    };

    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      updateNisn,
      siswas,
      changePage,
      changePerPage,
      perPageOptions,
      data,
      searchData,
      confirmDelete,
      units,
      unitlevel,
      kelasmaster,
      changeUnit,
      changeUnitlevel,
      changeKelas,
      datapermissions,
      isNumeric,
    
    };
  },
};
</script>
